import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../user.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-tables',
  templateUrl: './tables.component.html',
  styleUrls: ['./tables.component.scss']
})
export class UserTablesComponent implements OnInit {

  userId: string = null;
  tablesInfo = null;
  userInfo = null;
  joined: number = null;
  hosted: number = null;
  noShows: number = null;
  totalSpend: number = null;

  showTables: boolean = false;
  showTablesType:string = '';
  userReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  tablesReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
  ) { }

  async ngOnInit() {
    this.userId = this.route.parent.snapshot.paramMap.get('id');
    try {
      const result = await this.userService.getUserTableInfo(this.userId);
      this.userInfo = result.userInfo;
      this.joined = result.tablesJoined;
      this.hosted = result.tablesHosted;
      this.noShows = result.tablesNoShow;
      this.totalSpend = result.totalSpend;

    } catch (error) {
      console.error(error);
    }
    this.userReady.next(true);
  }

  async getTables(type: string){
    this.showTables = false;
    this.tablesReady.next(false);
    const tables = await this.userService.getUserTablesRecap(this.userId, type);
    this.tablesInfo = tables;
    this.showTables = true;
    this.showTablesType = type;
    this.tablesReady.next(true);
  }

}
