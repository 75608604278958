import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserDataService } from '../user-data.service';

interface pointsInfo {
  id: string,
  name: string,
  email: string,
  credit: number,
  pointsSpents: number,
}

@Component({
  selector: 'app-points-summary',
  templateUrl: './points-summary.component.html',
  styleUrls: ['./points-summary.component.scss']
})
export class PointsSummaryComponent implements OnInit {

  pointsInfo: pointsInfo[] = [];
  usersReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  searchAll: string = '';
  filteredUsers: any[];

  constructor(
    private dataService: UserDataService,
  ) { }

  async ngOnInit() {
    try {
      const result = await this.dataService.getUserPointsRecap();
      this.pointsInfo = result;
      this.filteredUsers = result;
    } catch (error) {
      console.error(error);
    }
    this.usersReady.next(true);
  }

  async filterAllUsers() {
    this.filteredUsers = this.pointsInfo.filter(item => new RegExp(this.searchAll, 'i').test(item.email));
  }

}
