import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { LaddaModule } from 'angular2-ladda';
import { ToasterModule } from 'angular2-toaster';
import { DataTablesModule } from 'angular-datatables';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { } from 'automapper-ts';
import { MomentModule } from 'angular2-moment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './routing/routing.module';
import { CoreModule } from './core/core.module';
import { HeaderComponent } from './layout/layout.header.component';
import { LayoutComponent } from './layout/layout.component';
import { LoginComponent } from './login/login.component';
import { NavigationComponent } from './layout/layout.navigation.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BreadcrumbComponent } from './layout/layout.breadcrumb.component';
import { TruncatePipe } from './shared/limit.to';
import { JSONEditorModule } from 'ngx-jsoneditor';
import { EntitiesModule } from './entities/entities.module';
import { GenericFormModule } from './generic-form/generic-form.module';
import { UsersModule } from './users/users.module';
import { EventsModule } from './event/event.module';
import { ModalModule, TabsModule, BsDatepickerModule } from 'ngx-bootstrap';
import { EnumsModule } from './enums/enums.module';
import { ProductsModule } from './products/products.module';
import { PagesModule } from './pages/pages.module';
import { EditorModule } from '@tinymce/tinymce-angular';
import { MappersModule } from './mappers/mappers.module';
import { VenueModule } from './venue/venue.module';
import { ReportModule } from './reports/report.module';
import { FileUploadModule } from './file.upload/file.upload.module';
import { HappyHourModule } from './happy-hour/happy-hour.module';
import { LandingComponent } from './login/landing.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { NgxPrintModule } from 'ngx-print';
import { SummariesModule } from './summaries/summaries.module';
import { PostsModule } from './post/post.module';
import { BansModule } from './bans/bans.module';
import { SubscriptionsModule } from './subscriptions/subscriptions.module';
import { PromoCodesModule } from './promo-codes/promo-codes.module';
import { ReferralCodesModule } from './referral-codes/referral-codes.module';
import { PremiumSubscriptionsModule } from './premium-subscriptions/premium-subscriptions.module';
import { UserDataModule } from './user-data/user-data.module';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LayoutComponent,
    HeaderComponent,
    NavigationComponent,
    LandingComponent,
    BreadcrumbComponent,
    TruncatePipe,
  ],
  imports: [
    BrowserModule,
    CoreModule,
    UsersModule,
    EventsModule,
    ReportModule,
    VenueModule,
    BansModule,
    SubscriptionsModule,
    PromoCodesModule,
    ReferralCodesModule,
    PremiumSubscriptionsModule,
    UserDataModule,
    DashboardModule,
    EnumsModule,
    ProductsModule,
    PagesModule,
    SummariesModule,
    PostsModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    NgbModule,
    LaddaModule.forRoot({
      spinnerColor: '#aaa',
    }),
    NoopAnimationsModule,
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ToasterModule.forRoot(),
    DataTablesModule,
    MomentModule,
    JSONEditorModule,
    EntitiesModule,
    GenericFormModule,
    EditorModule,
    MappersModule,
    FileUploadModule,
    HappyHourModule,
    NgxPrintModule
  ],
  exports: [TruncatePipe, NgxPrintModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
