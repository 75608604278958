import { Injectable } from '@angular/core';
import { ApiService } from '../core/api.service';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  constructor(
    private ApiService: ApiService,
  ) { }

  async getUserTablesRecap(){
    const res = await this.ApiService.get(`/admin/users/referralInfo`, []);
    return res;
  }

  async getUserPointsRecap(){
    const res = await this.ApiService.get(`/admin/users/points`, []);
    return res;
  }

  async getTablesBasicInfo(page: number, limit: number, search?: string){
    const params = new URLSearchParams({
      page: page.toString(),
      limit: limit.toString(),
    });
    if (search) {
      params.append("search", search);
    }
    const res = await this.ApiService.get(`/admin/tables/summary?${params.toString()}`, []);
    return res;
  };

}
