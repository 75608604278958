import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserService } from './user.service';
import { EntityListComponent } from '../entities/entity-list/entity-list.component';
import { UserServiceResolver } from './user.service.resolver';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';
import { EntityEditComponent } from '../entities/entity-edit/entity-edit.component';
import { DummyComponent } from '../shared/dummy.component';
import { EntityCreateComponent } from '../entities/entity-create/entity-create.component';
import { AuthenticatedGuard } from '../core/authenticated.guard';
import { SharedModule } from '../shared/shared.module';
import { UserGalleryComponent } from './user-gallery/user-gallery.component';
import { UserPasswordComponent } from './user-password/user-password.component';
import { LaddaModule } from 'angular2-ladda';
import { GenericFormModule } from '../generic-form/generic-form.module';
import { UserRoles } from './user.roles';
import { UserAmbassadorComponent } from './user-ambassador/user-ambassador.component';
import { UserTablesComponent } from './tables/tables.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    LaddaModule,
    GenericFormModule,
    RouterModule.forChild([{
      path: 'users',
      component: LayoutComponent,
      data: {
        sideMenuConfig: {
          route: '/users',
          icon: 'glyphicon-user',
          label: 'Users',
          category: 'users',
          index: 0
        },
        access: [UserRoles.ADMIN]
      },
      children: [
        {
          path: '',
          component: EntityListComponent,
          canActivate: [AuthenticatedGuard],
          resolve: { service: UserServiceResolver },
          data: { category: 'users', title: 'Users' }
        },
        {
          path: 'new',
          component: EntityCreateComponent,
          canActivate: [AuthenticatedGuard],
          resolve: { service: UserServiceResolver },
          data: { category: 'users', title: 'Create User' }
        },
        {
          path: ':id',
          component: EntityEditComponent,
          canActivate: [AuthenticatedGuard],
          resolve: { service: UserServiceResolver },
          data: {
            category: 'users',
            title: 'User Info',
            tabs: [{
              path: 'gallery',
              label: 'Gallery'
            },
            {
              path: 'password',
              label: 'Password'
            },{
              path: 'ambassador',
              label: 'Ambassador'
            },{
              path: 'tables',
              label: 'Tables'
            }]
          },
          children: [{
            path: 'gallery',
            component: UserGalleryComponent,
            canActivate: [AuthenticatedGuard],
            data: { category: 'users', title: 'User Gallery' }
          },
          {
            path: 'password',
            component: UserPasswordComponent,
            canActivate: [AuthenticatedGuard],
            data: { category: 'users', title: 'User Password' }
          },
          {
            path: 'ambassador',
            component: UserAmbassadorComponent,
            canActivate: [AuthenticatedGuard],
            data: { category: 'users', title: 'Ambassador' }
          },
          {
            path: 'tables',
            component: UserTablesComponent,
            canActivate: [AuthenticatedGuard],
            data: { category: 'users', title: 'User Tables' }
          }]
        }
      ]
    }])
  ],
  declarations: [UserGalleryComponent, UserPasswordComponent, UserAmbassadorComponent, UserTablesComponent],
  providers: [UserService, UserServiceResolver]
})
export class UsersModule {}
