import { Injectable } from '@angular/core';

import { IEntityService } from '../entities/entity.service';
import { ApiService } from '../core/api.service';
import { IValidationRule } from '../core/model.validator';
import { AutomapperService } from '../core/automapper.service';
import { GenericFormType } from '../generic-form/generic-form.component';
import { AmbassadorCodesConstraints, UserCreateConstraints, UserUpdateConstraints, VenueOwnerConstraints } from './user.constraints';
import { ITableColumn } from '../entities/ITableColumn';
import { IAmbassadorData, IAmbassadorDataReport } from './user-ambassador/user-ambassadorData';

@Injectable()
export class UserService implements IEntityService {

  constructor(
    private ApiService: ApiService,
    private AutomapperService: AutomapperService) { }

  getName(): string {
    return 'users';
  }

  getAll(): Promise<any[]> {
    return this.ApiService.get('/admin/users', [])
      .then((users: any[]) => {
        return this.AutomapperService.mapArray('UserApi', 'UserModel', users);
      });
  }

  getOne(query: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  find(query: any): Promise<any[]> {
    throw new Error('Method not implemented.');
  }

  getByEmails(emails: any): Promise<any[]> {
    return this.ApiService.post('/users/search/email', {
      emails
    }, [])
      .then((users: any[]) => {
        return users;
      });
  }

  getById(id: string): Promise<any> {
    return this.ApiService.get('/admin/users/' + id, [])
      .then((user) => {
        const userMaped = this.AutomapperService.map('UserApi', 'UserModel', user);
        return userMaped;
      });
  }

  create(entity: any): Promise<any> {
    return this.ApiService.post('/admin/users', entity, []);
  }

  update(entity: any): Promise<any> {
    const entityMaped: any = this.AutomapperService.map('UserModel', 'UserApi', entity);
    return this.ApiService.put('/users/' + entityMaped.id, entityMaped, []);
  }

  delete(entity: any): Promise<boolean> {
    return this.ApiService.delete('/users/' + entity.id, []);
  }

  newDelete(entity: any, reason: string): Promise<boolean> {
    return this.ApiService.delete('/users/' + entity.id + `?banReason=${encodeURIComponent(reason)}` , []);
  }

  changePassword(entity: any, userId: string): Promise<any> {
    return this.ApiService.put(`/users/${userId}/admin-password-change`, entity, []);
  }

  getAmbassadorData(ambassadorId: string, dateFrom: string, dateTo: string): Promise<IAmbassadorDataReport[]> {
    return this.ApiService.post(
      `/user/ambassador-data`,
      { ambassadorId, dateFrom: dateFrom, dateTo: dateTo },
      [],
    );
  }

  async getAllAmbassadorData(dateFrom: string, dateTo: string): Promise<IAmbassadorDataReport[]> {
    const res = await this.ApiService.post(
      `/user/ambassador-data`,
      { dateFrom: dateFrom, dateTo: dateTo },
      [],
    )
    return res;
  }

  async getUserTableInfo(id: string) {
    const res = await this.ApiService.get(`/admin/user/${id}/tablesInfo`, []);
    return res;
  }

  async getUserTablesRecap(id: string, type: string){
    const res = await this.ApiService.get(`/admin/user/${id}/recap/${type}`, []);
    return res;
  }

  getTableColumns(): ITableColumn[] {
    return [{
      index: 0,
      type: 'text',
      name: 'email',
      label: 'Email',
      hidden: false
    }, {
      index: 1,
      type: 'text',
      name: 'fullName',
      label: 'Name',
      hidden: false
    }, {
      index: 2,
      type: 'date',
      name: 'createdAt',
      label: 'Created At',
      hidden: false
    }, {
      index: 3,
      type: 'select',
      options: ['ADMIN', 'REGULAR', 'VENDOR', 'TEMPORARY'],
      name: 'role',
      label: 'Role',
      hidden: false
    }, {
      index: 4,
      type: 'select',
      options: ['Visible', 'Hidden'],
      name: 'active',
      label: 'Visibility',
      hidden: false
    }, {
      index: 5,
      type: "text",
      name: "isStudent",
      label: "Is Student",
      hidden: false,
    }, {
      index: 6,
      type: "text",
      name: "referredBy",
      label: "Referred by",
      hidden: false,
    }];
  }

  getTableOptions(): any {
    return {
      order: [[2, 'desc']],
    };
  }

  getCreateConstraints(): GenericFormType[] {
    return UserCreateConstraints;
  }
  getUpdateConstraints(): GenericFormType[] {
    return UserUpdateConstraints;
  }
  getOwnerConstraints(): GenericFormType[] {
    return VenueOwnerConstraints;
  }
  getAmbassadorDataConstraints(): GenericFormType[] {
    return AmbassadorCodesConstraints;
  }
}
