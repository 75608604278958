import { Component, OnInit } from '@angular/core';
import { UserDataService } from '../user-data.service';
import { BehaviorSubject } from 'rxjs';

interface referralInfo {
  id: string,
  name: string,
  email: string,
  referraldCode: string,
  totalSpend: number,
  eventsJoined: number,
  eventsHosted: number,
  eventsNoShow: number,
}

@Component({
  selector: 'app-referral-code',
  templateUrl: './referral-code.component.html',
  styleUrls: ['./referral-code.component.scss']
})
export class ReferralCodeComponent implements OnInit {

  refInfo: referralInfo[] = [];
  tablesReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  searchAll: string = '';
  filteredUsers: any[];

  constructor(
    private dataService: UserDataService,
  ) { }

  async ngOnInit() {
    try {
      const result = await this.dataService.getUserTablesRecap();
      this.refInfo = result;
      this.filteredUsers = result;
    } catch (error) {
      console.error(error);
    }
    this.tablesReady.next(true);
  }

  async filterAllUsers() {
    this.filteredUsers = this.refInfo.filter(item => new RegExp(this.searchAll, 'i').test(item.email));
  }

}
